import { Button } from '../pattern/Button'
import { AboutH1, AboutLi, AboutLiSpan, AboutUl, AboutWrapper, ButtonCeter, SkillArea, Skill } from './About.styled'
import Typewriter from 'typewriter-effect'
import { Link } from 'react-router-dom'
import {
  SiGithub,
  SiHtml5,
  SiTailwindcss,
  SiCss3,
  SiSass,
  SiBootstrap,
  SiJavascript,
  SiReact,
  SiRedux,
  SiTypescript,
  SiNodedotjs,
  SiMongodb,
} from 'react-icons/si'

export const About = () => {
  return (
    <AboutWrapper>
      <AboutH1>
        <Typewriter
          options={{
            strings: ['My name is', 'SHIHO KAZAMA', 'I am', 'FRONT-END DEVELOPER'],
            autoStart: true,
            loop: true,
          }}
        />
      </AboutH1>

      <AboutH1>No goal in learning. I'll never stop learning.</AboutH1>
      <AboutUl>
        <AboutLi>
          <AboutLiSpan>Passionate about web development</AboutLiSpan>
          <br />I am a front-end developer who is eager to learn about new technologies.
        </AboutLi>
        <AboutLi>
          <AboutLiSpan>High potential as a developer</AboutLiSpan>
          <br />
          I'm pleased to code, learn about new things, solve problems.
        </AboutLi>
        <AboutLi>
          <AboutLiSpan>Bachelor of Multi-media</AboutLiSpan>
          <br />
          I've had a bachelor of multi-media. Now, I'm furthering my professional web development studies at Cornerstone
          International Community College of Canada. I'm looking for a job as a front-end developer.
        </AboutLi>
      </AboutUl>
      <SkillArea>
        <Skill>
          HTML5
          <SiHtml5 />
        </Skill>
        <Skill>
          CSS3
          <SiCss3 />
        </Skill>
        <Skill>
          Sass
          <SiSass />
        </Skill>
        <Skill>
          TailwindCSS
          <SiTailwindcss />
        </Skill>
        <Skill>
          BootStrap
          <SiBootstrap />
        </Skill>
        <Skill>
          Javascript(ES6)
          <SiJavascript />
        </Skill>
        <Skill>
          React
          <SiReact />
        </Skill>
        <Skill>
          Redux
          <SiRedux />
        </Skill>
        <Skill>
          TypeScript
          <SiTypescript />
        </Skill>
        <Skill>
          GitHub
          <SiGithub />
        </Skill>
        <Skill>
          Node.js
          <SiNodedotjs />
        </Skill>
        <Skill>
          MongoDB
          <SiMongodb />
        </Skill>
        <Skill>and leaning more!</Skill>
      </SkillArea>
      <ButtonCeter>
        <Link to="/about">
          <Button title={'ABOUT ME'}></Button>
        </Link>
      </ButtonCeter>
    </AboutWrapper>
  )
}
