import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { GrobalStyles } from '../../styles/Globals.styled'
import { NavBar } from '../pattern/NavBar'
import { ThemeProvider } from 'styled-components'
import { theme, darkTheme } from '../../styles/Globals.styled'
import { useSetTheme } from '../../../hooks/useSetTheme'
import {
  ContentTitle,
  ContentWarapper,
  HoverMsg,
  Line,
  Top,
  TopImg,
  SubImg,
  Section,
  TextSection,
  SectionRev,
  TextH1,
  TextP,
  DescSection,
  Content,
  BackBtn,
} from './Detail.styled'
import { ModeBtn, ModeBtnWrapperAbout } from '../Home/Hero.styled'
import { MdModeNight, MdWbSunny } from 'react-icons/md'
import Data from '../../../ProjectData.json'
import { Skills, Skill } from '../Projects/Detail.styled'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { motion } from 'framer-motion'
import { ProjectLinks, ProjectLink } from '../Work/WorkCard.styled'
import { GoMarkGithub, GoBrowser } from 'react-icons/go'

export const Webeginner = () => {
  const { modeTheme, setNextMode } = useSetTheme()
  const data = Data.projects.filter((project) => project.slug === 'webeginner')[0]
  const [hover, setHover] = useState<boolean>(false)
  const width = window.innerWidth
  const handleHover = useCallback(() => {
    setHover((prev) => !prev)
  }, [])
  const navigate = useNavigate()
  return (
    <>
      <GrobalStyles />
      <ThemeProvider theme={modeTheme === 'light' ? theme : darkTheme}>
        <Content>
          <NavBar />
          <ContentWarapper>
            <ContentTitle>{data.title}</ContentTitle>
            <Line />
            <ModeBtnWrapperAbout>
              <ModeBtn onClick={() => setNextMode(modeTheme)}>
                {modeTheme === 'light' ? (
                  <MdModeNight size={width > 768 ? '25px' : '18px'} color={'#594a4e'} />
                ) : (
                  <MdWbSunny size={width > 768 ? '25px' : '18px'} color={'#fffffe'} />
                )}
              </ModeBtn>
            </ModeBtnWrapperAbout>
            <Skills>
              {data.skills.map((skill, i) => (
                <Skill key={i}>{skill}</Skill>
              ))}
            </Skills>
            <Top onMouseEnter={handleHover} onMouseLeave={handleHover}>
              <HoverMsg>\\Hover Me//</HoverMsg>
              {hover ? (
                <TopImg src={`../${data.gif}`} alt="project_featured_image" />
              ) : (
                <TopImg src={`../${data.thumbnail}`} alt="project_featured_image" />
              )}
            </Top>
            <ProjectLinks>
              <ProjectLink href={data.github} target="blank">
                <GoMarkGithub size={width > 768 ? '30px' : '25px'} color={'#594a4e'} />
              </ProjectLink>
              <ProjectLink href={data.url} target="blank">
                <GoBrowser size={width > 768 ? '30px' : '25px'} color={'#594a4e'} />
              </ProjectLink>
            </ProjectLinks>
            <Line />
            <Section>
              <TextSection>
                <TextH1>Story</TextH1>
                <TextP>
                  This is a serverless application. I wanted to learn AWS so I stared to learn from AWS amplify. It can
                  sign in/login and have basic CRUD functions.
                  <br />I started to learn web development in 2020 via books, websites and tutorial videos. However,
                  Someday I realized that I was in “Tutorial hell”. ”Tutorial hell” means the situation we can't find
                  the proper tutorials that we need because there're tons of tutorials on the web world, old and new,
                  good and bad, short and long. When I learn to React to my self-study, Some of the websites and
                  tutorials for beginners are using “Class Components” and didn't explain “Functional Components”. So, I
                  thought It's useful for beginners if there's a website which can communicate with developers and share
                  recommended tutorials.
                </TextP>
                <br />
                <TextH1>- Technical stack</TextH1>
                <TextP>
                  <ul>
                    <li>- Authentication using Amazon Cognito</li>
                    <li>- Database using DynamoDB</li>
                    <li>- GraphQL API using Amazon AppSync</li>
                    <li>- Storage for images using S3 Buckets</li>
                    <li></li>
                  </ul>
                </TextP>
              </TextSection>
              <SubImg src="../img/webeginner/01.jpg" alt="siteimage" />
            </Section>
            <Line />
            <SectionRev>
              <TextSection>
                <TextH1>Features - Authentication using Amazon Cognito </TextH1>
                <TextP>
                  Users can register and log in and then post recommended tutorials or comments to others' posts. When
                  users register on this website, users will receive two factors authentication code.
                </TextP>
              </TextSection>
              <SubImg src="../img/webeginner/02.jpg" alt="siteimage" />
            </SectionRev>
            <Section>
              <TextSection>
                <TextH1>Features - CRUD Function </TextH1>
                <TextP>This web app has a fundamental CRUD function for posts with a featured image.</TextP>
              </TextSection>
              <SubImg src="../img/webeginner/03.jpg" alt="siteimage" />
            </Section>
            <SectionRev>
              <TextSection>
                <TextH1>Features - Comments Function</TextH1>
                <TextP>
                  Each post has comments post/view areas and users can communicate with other users through the Comments
                  function.
                </TextP>
              </TextSection>
              <SubImg src="../img/webeginner/04.jpg" alt="siteimage" />
            </SectionRev>
            <Line />
            <DescSection>
              <TextH1>Challenging part</TextH1>
              <TextP>
                I have an experience to use GraphQL but it was hard part for me because AWS amplify is so kind of
                begginer people since they've already prepared graphQL Query/Mutation. However in my case, it took time
                to figire out file structurte and mofdified to code can store comments.
              </TextP>
            </DescSection>
            <BackBtn as={motion.button} whileHover={{ scale: 1.1 }} onClick={() => navigate(-1)}>
              <IoArrowBackCircleOutline
                size={width > 768 ? '40px' : '30px'}
                color={modeTheme === 'light' ? '#594a4e' : '#232946'}
              />
            </BackBtn>
          </ContentWarapper>
        </Content>
      </ThemeProvider>
    </>
  )
}
