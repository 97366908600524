import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { GrobalStyles } from '../../styles/Globals.styled'
import { NavBar } from '../pattern/NavBar'
import { ThemeProvider } from 'styled-components'
import { theme, darkTheme } from '../../styles/Globals.styled'
import { useSetTheme } from '../../../hooks/useSetTheme'
import {
  ContentTitle,
  ContentWarapper,
  HoverMsg,
  Line,
  Top,
  TopImg,
  TextH1,
  TextP,
  DescSection,
  Content,
  BackBtn,
  DescHead,
} from './Detail.styled'
import { ModeBtn, ModeBtnWrapperAbout } from '../Home/Hero.styled'
import { MdModeNight, MdWbSunny } from 'react-icons/md'
import Data from '../../../ProjectData.json'
import { Skills, Skill } from './Detail.styled'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { motion } from 'framer-motion'
import { ProjectLinks, ProjectLink } from '../Work/WorkCard.styled'
import { GoMarkGithub, GoBrowser } from 'react-icons/go'

export const Portfolio = () => {
  const { modeTheme, setNextMode } = useSetTheme()
  const data = Data.projects.filter((project) => project.slug === 'portfolio')[0]
  const [hover, setHover] = useState<boolean>(false)
  const width = window.innerWidth
  const handleHover = useCallback(() => {
    setHover((prev) => !prev)
  }, [])
  const navigate = useNavigate()
  return (
    <>
      <GrobalStyles />
      <ThemeProvider theme={modeTheme === 'light' ? theme : darkTheme}>
        <Content>
          <NavBar />
          <ContentWarapper>
            <ContentTitle>{data.title}</ContentTitle>
            <Line />
            <ModeBtnWrapperAbout>
              <ModeBtn onClick={() => setNextMode(modeTheme)}>
                {modeTheme === 'light' ? (
                  <MdModeNight size={width > 768 ? '25px' : '18px'} color={'#594a4e'} />
                ) : (
                  <MdWbSunny size={width > 768 ? '25px' : '18px'} color={'#fffffe'} />
                )}
              </ModeBtn>
            </ModeBtnWrapperAbout>
            <Skills>
              {data.skills.map((skill, i) => (
                <Skill key={i}>{skill}</Skill>
              ))}
            </Skills>
            <Top onMouseEnter={handleHover} onMouseLeave={handleHover}>
              <HoverMsg>\\Hover Me//</HoverMsg>
              {hover ? (
                <TopImg src={`../${data.gif}`} alt="project_featured_image" />
              ) : (
                <TopImg src={`../${data.thumbnail}`} alt="project_featured_image" />
              )}
            </Top>
            <ProjectLinks>
              <ProjectLink href={data.github} target="blank">
                <GoMarkGithub size={width > 768 ? '30px' : '25px'} color={'#594a4e'} />
              </ProjectLink>
              <ProjectLink href={data.url} target="blank">
                <GoBrowser size={width > 768 ? '30px' : '25px'} color={'#594a4e'} />
              </ProjectLink>
            </ProjectLinks>
            <Line />
            <DescSection>
              <TextH1>Description - </TextH1>
              <TextP>I did my best to create this portfolio website in every aspect.</TextP>
              <DescHead>1 - Challenged using TypeScript</DescHead>
              <TextP>
                I was challenged to make this using TypeScript with React. I learned just how to declare in class. I've
                never used it for projects. A portfolio website doesn't have a complicated structure in general. I
                thought it was good for learning how to use Typescript through this portfolio. Eventually, I was able to
                get used to TypeScript!
              </TextP>
              <DescHead>2 -Styling</DescHead>
              <TextP>
                I can handle Tailwind CSS, Bootstrap and so on however I didn't use these this time. The reason is that
                I'd have liked to show my solid CSS knowledge and how I'm serious about becoming a front-end developer.
                I also designed the whole of this site. I wrote CSS that includes dark-mode parts using
                "styled-components".
              </TextP>
            </DescSection>
            <BackBtn as={motion.button} whileHover={{ scale: 1.1 }} onClick={() => navigate(-1)}>
              <IoArrowBackCircleOutline
                size={width > 768 ? '40px' : '30px'}
                color={modeTheme === 'light' ? '#594a4e' : '#232946'}
              />
            </BackBtn>
          </ContentWarapper>
        </Content>
      </ThemeProvider>
    </>
  )
}
